<template>
  <div class="orderList container">
    <el-tabs v-model="status" @tab-click="statusChange">
      <el-tab-pane label="全部" name="0" />
      <el-tab-pane label="待付款" name="1" />
      <el-tab-pane label="待成团" name="7" />
      <el-tab-pane label="待发货" name="2" />
      <el-tab-pane label="待收货" name="3" />
      <el-tab-pane label="已完成" name="4" />
      <el-tab-pane label="已取消" name="5" />
      <!-- <el-tab-pane label="售后/退款"
                   name="6" /> -->
    </el-tabs>
    <div class="searchbox">
      <el-form inline>
        <el-form-item label="下单时间">
          <el-date-picker
            v-model="create_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="完成时间">
          <el-date-picker
            v-model="completion_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="付款时间">
          <el-date-picker
            v-model="pay_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发货时间">
          <el-date-picker
            v-model="send_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <!-- </div> -->
        <!-- <div class="searchBox"> -->
        <el-form-item label="订单编号">
          <el-input v-model="order_sn" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="快递单号">
          <el-input v-model="express_no" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="用户姓名/手机号">
          <el-input v-model="keywords" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="支付方式">
          <el-select v-model="pay_type" placeholder="请选择">
            <el-option label="全部" value="0"></el-option>
            <el-option label="微信支付" value="1"></el-option>
            <el-option label="支付宝支付" value="2"></el-option>
            <el-option label="钱包支付" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付途径">
          <el-select v-model="pay_object" placeholder="请选择">
            <el-option label="全部" value="0"></el-option>
            <el-option label="自己支付" value="1"></el-option>
            <el-option label="他人支付" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="售后状态" v-show="status == 6">
          <el-select v-model="refund_status" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option label="未退款" value="0"></el-option>
            <el-option label="已退款" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单标签">
          <el-select v-model="order_label" placeholder="请选择">
            <el-option label="全部" value="0"></el-option>
            <el-option label="积分订单" value="1"></el-option>
            <el-option label="兑换订单" value="2"></el-option>
            <el-option label="养生卡权益订单" value="3"></el-option>
            <el-option label="医生推荐订单" value="4"></el-option>
            <el-option label="礼品卡订单" value="5"></el-option>
            <el-option label="歧黄学堂自购订单" value="6"></el-option>
            <el-option label="歧黄学堂推荐订单" value="7"></el-option>
            <el-option label="芸豆订单" value="8"></el-option>
            <el-option label="终身分佣订单" value="9"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发货状态">
          <el-select v-model="delivery_status" placeholder="请选择">
            <el-option label="全部" value="0"></el-option>
            <el-option label="全部发货" value="1"></el-option>
            <el-option label="部分发货" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收货人信息">
          <el-input
            v-model="consignee_keywords"
            placeholder="姓名/手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单类型">
          <el-select v-model="is_group_order" placeholder="请选择">
            <el-option label="全部" value="0"></el-option>
            <el-option label="普通订单" value="1"></el-option>
            <el-option label="拼团订单" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button @click="reset">重置</el-button>
        <div class="right" style="margin-top: 10px">
          <el-button
            type="primary"
            v-if="module_id_array.includes('111')"
            @click="exportOrderLists"
            >导出商品明细</el-button
          >
          <el-button
            v-if="module_id_array.includes('112')"
            @click="exportOrderPayMentLists"
            >导出订单列表</el-button
          >
          <el-button
            v-if="module_id_array.includes('110')"
            @click="stockShow = true"
            >导入批量发货</el-button
          >
        </div>
      </el-form>
    </div>

    <div class="table-box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; min-width: 1000px"
        :header-cell-style="{ background: '#F5F5F5' }"
        @sort-change="sortChange"
      >
        <el-table-column label="订单编号" min-width="280px">
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              <span>{{ scope.row.order_sn }}</span>
              <el-popover placement="right" width="400" trigger="hover">
                <div class="pop_box">
                  <div class="pop_title">订单信息</div>
                  <div class="pop_main">
                    <div class="pop-infobox">
                      <span class="pop-head">订单编号：</span>
                      <span class="pop-info">{{ scope.row.order_sn }}</span>
                    </div>
                    <div class="pop-infobox">
                      <span class="pop-head">下单时间：</span>
                      <span class="pop-info">{{
                        dataPars(scope.row.create_time)
                      }}</span>
                    </div>
                    <div class="pop-infobox">
                      <span class="pop-head">完成时间：</span>
                      <span class="pop-info">{{
                        dataPars(scope.row.completion_time) || ""
                      }}</span>
                    </div>
                    <div class="pop-infobox">
                      <span class="pop-head">发货时间：</span>
                      <span class="pop-info">{{
                        dataPars(scope.row.send_time) || ""
                      }}</span>
                    </div>
                    <div class="pop-infobox">
                      <span class="pop-head">配送方式：</span>
                      <span class="pop-info">{{ scope.row.delivery }}</span>
                    </div>
                    <div class="pop-infobox">
                      <span class="pop-head">支付方式：</span>
                      <span class="pop-info">{{ scope.row.pay_type }}</span>
                    </div>
                    <div class="pop-infobox">
                      <span class="pop-head">支付途径：</span>
                      <span class="pop-info">{{ scope.row.pay_object }}</span>
                    </div>
                  </div>
                </div>
                <span slot="reference"
                  ><img
                    src="../../assets/orderinfo.svg"
                    style="
                      margin-left: 5px;
                      object-fit: cover;
                      object-position: top;
                    "
                /></span>
              </el-popover>
              <span
                v-if="Number(scope.row.integral_price)"
                class="integral_price"
                >积分订单</span
              >
              <span v-if="Number(scope.row.card_price)" class="card_price"
                >兑换订单</span
              >
              <span v-if="Number(scope.row.is_exchange)" class="card_price1"
                >养生卡权益订单</span
              >
              <span v-if="Number(scope.row.is_doc_recommend)" class="card_price2"
                >医生推荐订单</span
              >
              <span v-if="Number(scope.row.gift_money)>0" class="card_price2"
                >礼品卡订单</span
              >
              <span v-if="Number(scope.row.is_school_recommend)==1" class="card_price2"
                >歧黄学堂推荐订单</span
              >
              <span v-if="Number(scope.row.is_school_recommend)==2" class="card_price2"
                >歧黄学堂自购订单</span
              >
              <span v-if="Number(scope.row.bean_price)>0" class="card_price2"
                >芸豆订单</span
              >
              <span v-if="Number(scope.row.is_doc_forever)" class="card_price2"
                >终身分佣订单</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="用户姓名"
          width="90px"
          prop="real_name"
        ></el-table-column>
        <el-table-column
          label="订单金额"
          prop="pay_money"
          sortable="custom"
          width="100px"
        ></el-table-column>
        <el-table-column label="订单类型" prop="is_group_order" width="100px">
          <template slot-scope="scope">
            <div v-if="scope.row.is_group_order == 0">普通订单</div>
            <div v-if="scope.row.is_group_order == 1">拼团订单</div>
            <div v-if="scope.row.is_group_order == 2">拼团订单</div>
          </template>
        </el-table-column>
        <el-table-column label="订单状态" min-width="200px" prop="status">
          <template slot-scope="scope">
            <div style="display:flex;align-items:center">
              <span>{{ scope.row.status }}</span>
              <span v-if="scope.row.delivery_status == 0" class="redText"
                >部分发货</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column label="收货人信息" min-width="200px" prop="status">
          <template slot-scope="scope">
            <div
              style="display:flex;align-items:center"
              v-if="scope.row.delivery == '自提'"
            >
              <span>自提</span>
            </div>
            <div style="display:flex;align-items:center" v-else>
              <span>{{ scope.row.consignee_name }}/</span>
              <span>{{ scope.row.consignee_phone }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单备注" prop="user_remark" min-width="170px">
          <template slot-scope="scope">
            <div
              style=" 
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ scope.row.user_remark }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="售后状态">
          <template slot-scope="scope">
            <span v-if="
                scope.row.refund_status == '已退款' ||
                (scope.row.refund_status == '待退款' &&
                  scope.row.status == '退款待审核')
              ">
              {{ scope.row.refund_status }}
            </span>
          </template>
        </el-table-column> -->
        <el-table-column
          label="付款时间"
          prop="pay_time"
          sortable="custom"
          width="auto"
        ></el-table-column>
        <el-table-column
          label="电话"
          width="auto"
          prop="phone"
        ></el-table-column>
        <el-table-column label="操作" width="auto">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="toOrderDetail(scope.row.order_id)"
              v-if="
                module_id_array.includes('74') ||
                  module_id_array.includes('999')
              "
              >查看
            </el-button>
            <el-button
              type="text"
              @click="cancle(scope.row)"
              v-if="
                scope.row.status == '待付款' &&
                  (module_id_array.includes('176') ||
                    module_id_array.includes('999'))
              "
              >取消订单
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      style="margin: 30px 0 40px 0"
    ></el-pagination>
    <Changestock :isFlag="stockShow" @refreshList="getList"></Changestock>
  </div>
</template>

<script>
import Changestock from "./Changestock.vue";
import commonUtil from "../../utils/commonUtil.js";
export default {
  name: "orderList",
  data() {
    return {
      status: this.$route.query.typeVal || "0",
      total: 0,
      page: 1,
      pageNumber: 10,
      create_time: [],
      completion_time: [],
      loading: true,
      pay_time: [],
      send_time: [],
      order_sn: "",
      express_no: "",
      keywords: "",
      pay_type: "",
      pay_object: "",
      refund_status: "",
      order_label: "",
      delivery_status: "",
      sort_type: "",
      tableData: [],
      module_id_array: window.utils.storage.getter("module_id_array") || [],
      stockShow: false,
      popInfo: [],
      consignee_keywords: "",
      is_group_order: "",
    };
  },
  components: {
    Changestock,
  },
  activated() {
    this.getList();
  },
  methods: {
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.tId != null) {
            let formData = new FormData();
            this.upload.fileList.forEach((file) => {
              formData.append("file", file.raw);
            });
            formData.append("tId", this.form.tId);
            formData.append("snakebiteType", this.form.snakebiteType);
            formData.append("programmeName", this.form.programmeName);
            formData.append("programmeDescribe", this.form.programmeDescribe);
            formData.append("orgName", this.form.orgName);
            this.bingOrgCode(this.form.orgName);
            formData.append("orgCode", this.form.orgCode);
            updateTreatment(formData).then((response) => {
              this.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            let formData = new FormData();
            this.upload.fileList.forEach((file) => {
              formData.append("file", file.raw);
            });
            formData.append("snakebiteType", this.form.snakebiteType);
            formData.append("programmeName", this.form.programmeName);
            formData.append("programmeDescribe", this.form.programmeDescribe);
            formData.append("orgCode", this.form.orgCode);
            formData.append("orgName", this.form.orgName);
            addTreatment(formData).then((response) => {
              this.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    // 提交导入数据
    submitImport() {},
    getList() {
      this.stockShow = false;
      this.tableData = [];
      let data = {
        page: this.page,
        pageNumber: this.pageNumber,
        order_sn: this.order_sn,
        express_no: this.express_no,
        keywords: this.keywords,
        pay_type: this.pay_type,
        pay_object: this.pay_object,
        refund_status: this.refund_status,
        sort_type: this.sort_type,
        status: this.status,
        order_label: this.order_label,
        delivery_status: this.delivery_status,
        consignee_keywords: this.consignee_keywords,
        is_group_order: this.is_group_order,
      };
      if (this.create_time && this.create_time[0]) {
        data.create_time_start = this.dayjs(this.create_time[0]).format(
          "YYYY-MM-DD"
        );
        data.create_time_end = this.dayjs(this.create_time[1]).format(
          "YYYY-MM-DD"
        );
      }
      if (this.completion_time && this.completion_time[0]) {
        data.completion_time_start = this.dayjs(this.completion_time[0]).format(
          "YYYY-MM-DD"
        );
        data.completion_time_end = this.dayjs(this.completion_time[1]).format(
          "YYYY-MM-DD"
        );
      }
      if (this.pay_time && this.pay_time[0]) {
        data.pay_time_start = this.dayjs(this.pay_time[0]).format("YYYY-MM-DD");
        data.pay_time_end = this.dayjs(this.pay_time[1]).format("YYYY-MM-DD");
      }
      if (this.send_time && this.send_time[0]) {
        data.send_time_start = this.dayjs(this.send_time[0]).format(
          "YYYY-MM-DD"
        );
        data.send_time_end = this.dayjs(this.send_time[1]).format("YYYY-MM-DD");
      }
      this.axios.post("/store/Shoporder/getOrderLists", data).then((res) => {
        this.total = res.data.total;
        let list = res.data.list;
        list.forEach((ele) => {
          if (ele.pay_time) {
            ele.pay_time = this.dayjs
              .unix(ele.pay_time)
              .format("YYYY-MM-DD HH:mm");
          }
          if (ele.status == 1) {
            ele.status = "待付款";
          } else if (ele.status == 2) {
            // ele.status = "待发货";
            if (ele.is_group_order != 1) {
              ele.status = "待发货";
            } else if (ele.is_group_order == 1) {
              ele.status = "待成团";
            }
          } else if (ele.status == 3) {
            ele.status = "待收货";
          } else if (ele.status == 4) {
            ele.status = "已完成";
          } else if (ele.status == 5) {
            ele.status = "已取消（超时未支付）";
          } else if (ele.status == 6) {
            ele.status = "已取消（用户取消）";
          } else if (ele.status == 7) {
            ele.status = "已取消（超时未发货）";
          } else if (ele.status == 8) {
            ele.status = "已取消（申请退款）";

            // } else if (ele.status == 8 && ele.refund_status == 0) {
            //   ele.status = '退款待审核';
            // } else if (ele.status == 8 && ele.refund_status == 1) {
            //   ele.status = '退款已审核';
          } else if (ele.status == 9) {
            ele.status = "已取消（商户取消）";
          } else if (ele.status == 10) {
            ele.status = "已取消（拼团失败）";
          }
          if (ele.refund_status == 0) {
            ele.refund_status = "待退款";
          } else if (ele.refund_status == 1) {
            ele.refund_status = "已退款";
          }
        });
        this.tableData = list;
        console.log("333", this.tableData);
        this.popInfo = [{}];
      });
    },
    statusChange() {
      this.page = 1;
      this.getList();
    },
    handleSizeChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getList();
    },
    handleCurrentChange(size) {
      this.page = size;
      this.getList();
    },
    cancle(val) {
      // 用户名称
      const name = val.real_name;
      // 订单编号
      const order = val.order_sn;
      const info = `取消后订单将更新为已取消订单哦，您确定取消${name}的订单${order}？`;
      this.$confirm(info, "确认取消", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          const data = {
            order_id: val.order_id,
          };
          this.axios.post("/store/Shoporder/cancleOrder", data).then((res) => {
            console.log(res);
            this.$message.success("取消成功");
          });
          this.getList();
        })
        .catch(() => {});
    },
    sortChange(e) {
      let prop = e.prop;
      let order = e.order;
      if (prop == "pay_money" && order == "ascending") {
        this.sort_type = 1;
      } else if (prop == "pay_money" && order == "descending") {
        this.sort_type = 2;
      } else if (prop == "create_time" && order == "descending") {
        this.sort_type = 3;
      } else if (prop == "create_time" && order == "ascending") {
        this.sort_type = 4;
      }
      this.getList();
    },
    reset() {
      commonUtil.lostFocus(event);
      this.create_time = [];
      this.completion_time = [];
      this.pay_time = [];
      this.send_time = [];
      this.order_sn = "";
      this.express_no = "";
      this.keywords = "";
      this.pay_type = "";
      this.pay_object = "";
      this.refund_status = "";
      this.sort_type = "";
      this.page = 1;
      this.order_label = "";
      this.delivery_status = "";
      this.consignee_keywords = "";
      this.is_group_order=""
      this.getList();
    },
    search() {
      commonUtil.lostFocus(event);
      this.page = 1;
      this.getList();
    },
    toOrderDetail(order_id) {
      commonUtil.lostFocus(event);
      this.$router.push({ path: "/order/orderDetail", query: { order_id } });
    },
    //商品明细
    exportOrderLists() {
      commonUtil.lostFocus(event);
      let data = {
        order_sn: this.order_sn,
        express_no: this.express_no,
        keywords: this.keywords,
        pay_type: this.pay_type,
        pay_object: this.pay_object,
        refund_status: this.refund_status,
        sort_type: this.sort_type,
        status: this.status,
        order_label: this.order_label,
        delivery_status: this.delivery_status,
        consignee_keywords: this.consignee_keywords,
        is_group_order: this.is_group_order,
      };
      if (this.create_time && this.create_time[0]) {
        data.create_time_start = this.dayjs(this.create_time[0]).format(
          "YYYY-MM-DD"
        );
        data.create_time_end = this.dayjs(this.create_time[1]).format(
          "YYYY-MM-DD"
        );
      }
      if (this.completion_time && this.completion_time[0]) {
        data.completion_time_start = this.dayjs(this.completion_time[0]).format(
          "YYYY-MM-DD"
        );
        data.completion_time_end = this.dayjs(this.completion_time[1]).format(
          "YYYY-MM-DD"
        );
      }
      if (this.pay_time && this.pay_time[0]) {
        data.pay_time_start = this.dayjs(this.pay_time[0]).format("YYYY-MM-DD");
        data.pay_time_end = this.dayjs(this.pay_time[1]).format("YYYY-MM-DD");
      }
      if (this.send_time && this.send_time[0]) {
        data.send_time_start = this.dayjs(this.send_time[0]).format(
          "YYYY-MM-DD"
        );
        data.send_time_end = this.dayjs(this.send_time[1]).format("YYYY-MM-DD");
      }
      this.axios.post("/store/shoporder/exportOrderLists", data).then((res) => {
        let host =
          document.location.protocol +
          "//" +
          window.location.host +
          res.data.url;

        window.open(host);
      });
    },
    //导出订单列表
    exportOrderPayMentLists() {
      commonUtil.lostFocus(event);
      let data = {
        order_sn: this.order_sn,
        express_no: this.express_no,
        keywords: this.keywords,
        pay_type: this.pay_type,
        pay_object: this.pay_object,
        refund_status: this.refund_status,
        sort_type: this.sort_type,
        status: this.status,
        order_label: this.order_label,
        delivery_status: this.delivery_status,
        consignee_keywords: this.consignee_keywords,
        is_group_order: this.is_group_order,
      };
      if (this.create_time && this.create_time[0]) {
        data.create_time_start = this.dayjs(this.create_time[0]).format(
          "YYYY-MM-DD"
        );
        data.create_time_end = this.dayjs(this.create_time[1]).format(
          "YYYY-MM-DD"
        );
      }
      if (this.completion_time && this.completion_time[0]) {
        data.completion_time_start = this.dayjs(this.completion_time[0]).format(
          "YYYY-MM-DD"
        );
        data.completion_time_end = this.dayjs(this.completion_time[1]).format(
          "YYYY-MM-DD"
        );
      }
      if (this.pay_time && this.pay_time[0]) {
        data.pay_time_start = this.dayjs(this.pay_time[0]).format("YYYY-MM-DD");
        data.pay_time_end = this.dayjs(this.pay_time[1]).format("YYYY-MM-DD");
      }
      if (this.send_time && this.send_time[0]) {
        data.send_time_start = this.dayjs(this.send_time[0]).format(
          "YYYY-MM-DD"
        );
        data.send_time_end = this.dayjs(this.send_time[1]).format("YYYY-MM-DD");
      }
      this.axios
        .post("/store/Shoporder/exportOrderPayMentLists", data)
        .then((res) => {
          let host =
            document.location.protocol +
            "//" +
            window.location.host +
            res.data.url;

          window.open(host);
        });
    },
    // 时间转换
    dataPars(val) {
      if (!val) {
        return null;
      }
      var date = new Date(val * 1000);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.orderList {
  .el-range-editor {
    width: 250px;
  }
}
.pop_box {
  .pop_title {
    font-weight: 700;
    font-size: 16px;
    color: #666666;
    border-bottom: 1px solid #dcdcde;
    margin-bottom: 5px;
    padding-bottom: 6px;
  }
  .pop_main {
    margin-top: 5px;
    .pop-infobox {
      margin: 3px;
    }
  }
}
.integral_price {
  display: flex;
  align-items: center;
  margin-left: 3px;
  padding: 0 3px;
  background: url("../../assets/integral_price.png");
  background-size: 100% 100%;
  color: #ffffff;
}
.card_price {
  display: flex;
  align-items: center;
  margin-left: 3px;
  padding: 0 3px;
  background: url("../../assets/card_price.png");
  background-size: 100% 100%;
  color: #ffffff;
}
.card_price1{
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-left: 3px;
  padding: 0 3px;
  background: url("../../assets/card_price1.png");
  background-size: 100% 100%;
  color: #ffffff;
}
.card_price2{
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-left: 3px;
  padding: 0 3px;
  background: url("../../assets/card_price2.png");
  background-size: 100% 100%;
  color: #ffffff;
}
.redText {
  background-color: rgb(236, 128, 141);
  color: #ffffff;
  padding: 0px 5px;
  margin-left: 5px;
}
</style>
